<template>
  <div class="search--listing-wrapper">
    <multiselect
      name="searchKeyword"
      @search-change="searchFieldChanged"
      v-model="currentKeyword"
      :optionsLimit="optionsLimit"
      :options="searchListingOptions"
      :searchable="true"
      :close-on-select="true"
      track-by="id"
      label="text"
      :placeholder="$t('myListing.placeholder.search')"
    >
      <!--      <template slot="caret">-->
      <!--        <div class="multiselect__select select&#45;&#45;search">-->
      <!--          <i class="ion-ios-search"></i>-->
      <!--        </div>-->
      <!--      </template>-->
    </multiselect>
    <div v-show="currentKeyword" class="reset--search">
      <button type="button" @click="resetSearch">
        <span class="ion-close"></span>
        <span class="reset--text">Reset Search</span>
      </button>
      <div v-show="isFetchingListing" class="tab_disabled"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'search-listing',
  components: {
    Multiselect,
  },
  data: () => ({
    optionsLimit: 0,
  }),
  created() {
    this.$store.dispatch('myListing/fetchSearchListingOptions');
    this.optionsLimit = 0;
  },
  computed: {
    ...mapState({
      searchListingOptions: state => state.myListing.searchListingOptions,
      isFetchingListing: state => state.myListing.isFetchingListing,
    }),
    currentKeyword: {
      get() {
        return this.$store.state.myListing.currentKeyword;
      },
      set(value) {
        this.$store.commit('myListing/SET_CURRENT_KEYWORD', value);
      },
    },
  },
  watch: {
    currentKeyword() {
      let query = Object.assign({}, this.$route.query);
      delete query.page;
      this.$router.replace({ query });
      this.$store.dispatch('myListing/getListings');
    },
  },
  methods: {
    searchFieldChanged(searchQuery) {
      if (searchQuery) {
        this.optionsLimit = 5;
      } else {
        this.optionsLimit = 0;
      }
    },
    resetSearch() {
      if (!this.isFetchingListing) {
        this.$store.commit('myListing/SET_CURRENT_KEYWORD', '');
      }
    },
  },
};
</script>

<style scoped></style>
