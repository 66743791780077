<template>
  <section class="my--listing-listings">
    <SectionLoader :show="isFetchingListing" />
    <div v-for="(listing, index) in listingItems" :key="index" class="listing--item">
      <card-listing
        :checkBankAccountDone="checkBankAccountDone"
        :listing="listing"
        :page="$route.query.page"
      ></card-listing>
    </div>
  </section>
</template>

<script>
import CardListing from '@/components/dashboard/my-listing/card-listing';
import { mapState } from 'vuex';

const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  props: ['checkBankAccountDone'],
  components: {
    CardListing,
    SectionLoader,
  },
  computed: {
    ...mapState({
      tabActive: state => state.myListing.activeCategory,
      sortBys: state => state.myListing.sortByNavList,
      isFetchingListing: state => state.myListing.isFetchingListing,
      actionLoading: state => state.myListing.actionLoading,
      listingItems: state => state.myListing.listingItemList,
      metaPagination: state => state.myListing.metaPagination,
    }),
  },
};
</script>
