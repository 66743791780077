<template>
  <div class="active--switch">
    <label :for="`occupationToggle${listingUuid}`" class="custom--switch">
      <input
        :id="`occupationToggle${listingUuid}`"
        type="checkbox"
        :checked="!occupied"
        @click.prevent="inputHandler"
      />
      <span class="slider"></span>
    </label>
    <label :for="`occupationToggle${listingUuid}`" class="switch--label">
      {{
        occupied
          ? $t(`general.btn.occupied.${listingType}`)
          : $t(`general.btn.notOccupied.${listingType}`)
      }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'occupation-toggle',
  props: {
    occupied: {
      type: Boolean,
      default: false,
    },
    listingType: {
      type: String,
      required: true,
    },
    listingUuid: {
      type: String,
      required: true,
    },
    checkBankAccountDone: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      default: null,
    },
    fromMylisting: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    actionLoading: {
      get() {
        return this.$store.state.myListing.actionLoading;
      },
      set(val) {
        this.$store.commit('myListing/SET_ACTION_LOADING', val);
      },
    },
  },
  methods: {
    async activate() {
      try {
        const result = await this.$swal({
          title: this.$i18n.t(`myListing.modal.occupied.${this.listingType}.title`),
          text: this.$i18n.t(`myListing.modal.occupied.${this.listingType}.message`),
          type: 'error',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          allowOutsideClick: false,
        });
        if (result.value) {
          this.actionLoading = true;
          const success = await this.$store.dispatch('myListing/occupiedListing', this.listingUuid);
          this.actionLoading = false;
          if (success) {
            if (this.fromMylisting) {
              this.$store.dispatch('myListing/getListings', this.page);
              this.$store.dispatch('myListing/fetchSearchListingOptions');
            }
            // this.$store.dispatch('myListing/getCategoryCountData');
            await this.$swal(
              this.$i18n.t('general.success'),
              this.$i18n.t(`myListing.modal.occupied.${this.listingType}.success`),
              'success',
            );
            this.$emit('actionDone');
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.actionLoading = false;
      }
    },
    async deactivate() {
      try {
        const result = await this.$swal({
          title: this.$i18n.t(`myListing.modal.notOccupied.${this.listingType}.title`),
          text: this.$i18n.t(`myListing.modal.notOccupied.${this.listingType}.message`),
          type: 'error',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          allowOutsideClick: false,
        });
        if (result.value) {
          this.actionLoading = true;
          const success = await this.$store.dispatch(
            'myListing/notOccupiedListing',
            this.listingUuid,
          );
          this.actionLoading = false;
          if (success) {
            if (this.fromMylisting) {
              this.$store.dispatch('myListing/getListings', this.page);
              this.$store.dispatch('myListing/fetchSearchListingOptions');
            }
            // this.$store.dispatch('myListing/getCategoryCountData');
            await this.$swal(
              this.$i18n.t('general.success'),
              this.$i18n.t(`myListing.modal.notOccupied.${this.listingType}.success`),
              'success',
            );
            this.$emit('actionDone');
          }
        }
      } finally {
        this.actionLoading = false;
      }
    },
    inputHandler() {
      if (this.occupied) {
        this.deactivate();
      } else {
        this.activate();
      }
    },
  },
};
</script>

<style scoped></style>
